
import { ethers } from "ethers";
import React, { useState } from 'react';
import abo from "./abi.json";
import {
  ContractAddress,
  NetworkRPC,
} from "./globalValues.js";

export default function Dapp() {

  const [wallet, setWallet] = useState(null)
  const [smallAmount, setSmallAmount] = useState("0");
  const [mediumAmount, setMediumAmount] = useState("0");
  const [largeAmount, setLargeAmount] = useState("0");
  const [dailyReward, setDailyReward] = useState("0");
  const [pendingRewards, setPendingRewards] = useState("0");

  async function connect() {
    if (!window.ethereum) {
      alert('Metamask is not installed');
      return;
    }

    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      if (accounts && accounts.length > 0) {
        setWallet(accounts[0]);
        await getInfo();
      }
    } catch (err) {
    }
  }

  async function getInfo() {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      if (accounts.length === 0) {
        alert("No account found. Please connect to MetaMask.");
        return;
      }
      
      const provider = new ethers.JsonRpcProvider(NetworkRPC);
      const contractABI = abo;
      
      const contract = new ethers.Contract(ContractAddress, contractABI, provider);
      const large = await contract.LargeCup(accounts[0]);
      const medium = await contract.MediumCup(accounts[0]);
      const small = await contract.SmallCup(accounts[0]);
      const pending = await contract.earnings(accounts[0]);
      
      setSmallAmount(String(small));
      setMediumAmount(String(medium));
      setLargeAmount(String(large));

      let counterReward = 0;
      if (Number(large) !== 0) {
        counterReward += 150 * Number(large);
      }
      if (Number(medium) !== 0) {
        counterReward += 55 * Number(medium);
      }

      if (Number(small) !== 0) {
        counterReward += 20 * Number(small);
      }

      setDailyReward(String(counterReward));
      setPendingRewards(String((Number(pending) / 1_000_000_000_000_000_000).toFixed(4)))
    } catch (error) {
      console.log(error)
      // ignored
    }
  }

  async function claimWin() {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      const metaMaskProvider = new ethers.BrowserProvider(window.ethereum);
      const signer = await metaMaskProvider.getSigner(accounts[0]);

      const contractABI = abo;

      const contract = new ethers.Contract(
        ContractAddress,
        contractABI,
        signer
      );
      const tx = await contract.claim();
      await tx.wait();
      await getInfo();
    } catch (error) {
      // ignored
      console.log(error);
    }
  }

  async function purchaseSmall() {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      const metaMaskProvider = new ethers.BrowserProvider(window.ethereum);
      const signer = await metaMaskProvider.getSigner(accounts[0]);

      const contractABI = abo;

      const contract = new ethers.Contract(
        ContractAddress,
        contractABI,
        signer
      );
      const tx = await contract.purchaseSmall();
      await tx.wait();
      await getInfo();
    } catch (error) {
      // ignored
      console.log(error);
    }
  }

  async function purchaseMedium() {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      const metaMaskProvider = new ethers.BrowserProvider(window.ethereum);
      const signer = await metaMaskProvider.getSigner(accounts[0]);

      const contractABI = abo;

      const contract = new ethers.Contract(
        ContractAddress,
        contractABI,
        signer
      );
      const tx = await contract.purchaseMedium();
      await tx.wait();
      await getInfo();
    } catch (error) {
      // ignored
      console.log(error);
    }
  }

  async function purchaseLarge() {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      const metaMaskProvider = new ethers.BrowserProvider(window.ethereum);
      const signer = await metaMaskProvider.getSigner(accounts[0]);

      const contractABI = abo;

      const contract = new ethers.Contract(
        ContractAddress,
        contractABI,
        signer
      );
      const tx = await contract.purchaseLarge();
      await tx.wait();
      await getInfo();
    } catch (error) {
      // ignored
      console.log(error);
    }
  }

  return (
      <>
        <button id="toggleMuteButton" className="mute-button">
          <i className="fas fa-volume-up"></i>
        </button>
        <main>
          <section id="pricing">
            <h2 className="title">Plans</h2>
            <div id="Wallet">
              <button id="btn-connect" onClick={() => {connect()}}>{wallet ? wallet : 'Connect Wallet'}</button>
            </div>
            <div id="plan">

              <div className="offer">
                <img className="cup-img" src="./img/scup.png" />
                <div className="offer-info">
                  <h2>S-CUP</h2>
                  <p>Price 100 $LEAN</p>
                  <p>Earn 20 $LEAN a day (20% daily reward)</p>
                </div>
                <div>
                  <button style={{marginRight: "auto", marginLeft: "auto", marginTop: "40px"}} id="btn-connect" onClick={() => {purchaseSmall()}}>{'PURCHASE'}</button>
                </div>
            </div>

            <div className="offer">
                <img className="cup-img" src="./img/mcup.png" />
                <div className="offer-info">
                  <h2>M-CUP</h2>
                  <p>Price 250 $LEAN</p>
                  <p>Earn 55 $LEAN a day (22% daily reward)</p>
                </div>
                <div>
                  <button style={{marginRight: "auto", marginLeft: "auto", marginTop: "40px"}} id="btn-connect" onClick={() => {purchaseMedium()}}>{'PURCHASE'}</button>
                </div>
            </div>

            <div className="offer">
                <img className="cup-img" src="./img/lcup.png" />
                <div className="offer-info">
                  <h2>L-CUP</h2>
                  <p>Price 600 $LEAN</p>
                  <p>Earn 120 $LEAN a day (25% daily reward)</p>
                </div>
                <div>
                  <button style={{marginRight: "auto", marginLeft: "auto", marginTop: "40px"}} id="btn-connect" onClick={() => {purchaseLarge()}}>{'PURCHASE'}</button>
                </div>
            </div>


            </div>
          </section>

          <section id="claim">
            <h2 className="title">Claim Reward</h2>
            <div id="content-claim">
            <div id="drank">
            <h3>Your Drank</h3>
            <p>S Cup: {smallAmount}</p>
            <p>M Cup: {mediumAmount}</p>
            <p>L Cup: {largeAmount}</p>
            <p>Daily $LEAN reward: {dailyReward}</p>
            <p>Pending $LEAN rewards: {pendingRewards}</p>
            <button onClick={() => {claimWin()}}>Claim</button>
            </div>

            <div id="text">
              <p>Lean Party, fill the cups with $LEAN to receive daily rewards in $LEAN, the bigger your CUP is the more $LEAN you earn daily, enjoy your lean adventure with us.</p>
            </div>
          </div>
          </section>
          <audio id="backgroundSound" loop>
            <source src="./img/audio.mp3" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </main>
      </>
  );
};