import { useState } from "react";
import Dapp from "./Dapp";

function App() {
  
  const [dapp, setDapp] = useState(false);

  return (
    <>
    {
          dapp ? <Dapp /> : (
            <>
              <button id="toggleMuteButton" className="mute-button">
                <i className="fas fa-volume-up"></i>
              </button>
              <main>
                <section id="header">
                  <h1>LEAN PARTY</h1>
                  <img width="100%" src="./img/lean.png" />
                </section>
                <section id="dapp">
                  <a style={{cursor: "pointer"}} href="https://twitter.com/LeanPartyBase">Twitter</a>
                  <a style={{cursor: "pointer"}} href="https://t.me/LeanPartyPortal">Telegram</a>
                  <a style={{cursor: "pointer"}} href="https://dexscreener.com/base">Chart</a>
                </section>
                <section id="dapp" style={{height: "300px"}}>
                  <a style={{cursor: "pointer"}} onClick={() => {setDapp(true)}}>DAPP</a>
                </section>
                <audio id="backgroundSound" loop>
                  <source src="./img/audio.mp3" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </main>
            </>
          )
        }
      </>
  );
}

export default App;
